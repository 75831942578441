import { RaisedButton } from '@eduact/ed-components'
import { FlexLayout } from '@eduact/ed-system'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TrustedDevicesApi from '../Settings.api'
import Modal from '@Components/Utilities/Popup/Modal'
import DeviceCard from './DeviceCard'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@Hooks/ReduxHooks'
import { ClientJS } from 'clientjs'

const client = new ClientJS()

const Devices = () => {
	const [t] = useTranslation('settings')
	const [devices, setDevices] = useState<Array<Device>>([])
	const [openModel, setOpenModel] = useState<boolean>(false)
	const {
		classroomsSections: { device_limit },
	} = useAppSelector(state => state.settings)

	const getAllDevices = async () => {
		const {
			data: { data },
		} = await TrustedDevicesApi.getAllDevices()
		setDevices(data)
	}

	const logoutDevices = async () => {
		await TrustedDevicesApi.logoutAllDevices()
			.then(() => {
				getAllDevices()
			})
			.then(() => {
				setOpenModel(false)
			})
	}

	useEffect(() => {
		getAllDevices()
	}, [])

	return (
		<Container>
			<ModelStyle modalOpen={openModel}>
				<Modal.Content
					minWidth={'fit-content'}
					minHeight={'fit-content'}
					height="17.994rem"
					onClose={() => {
						setOpenModel(false)
					}}
				>
					<ContentWrapper justifyContent="space-between" alignItems="center" flexDirection="column" height="100%">
						<FlexLayout
							p={{ lg: '3.688rem', sm: '2.688rem' }}
							pb={{ lg: '0', sm: '0' }}
							flexDirection="column"
							alignItems="flex-start"
							gridGap="0.875rem"
						>
							<Text>{t('text')}</Text>
							<Note>{t('note')}</Note>
						</FlexLayout>
						<FlexLayout
							justifyContent="space-between"
							alignItems="center"
							width="100%"
							p={{ lg: '0 2.813rem 1.8rem', sm: '0 1.688rem 1.5rem' }}
						>
							<CancelButtonConfirm
								onClick={() => {
									setOpenModel(false)
								}}
							>
								{t('discard')}
							</CancelButtonConfirm>
							<ConfirmButton
								onClick={() => {
									logoutDevices()
								}}
							>
								{t('confirm')}
							</ConfirmButton>
						</FlexLayout>
					</ContentWrapper>
				</Modal.Content>
			</ModelStyle>

			<FlexLayout flexDirection="column">
				<FlexLayout
					alignItems="center"
					justifyContent="space-between"
					flexDirection={{ md: 'row', sm: 'column' }}
					p={{ lg: '0', sm: '1rem' }}
					gridGap={{ lg: '0', sm: '0.8rem' }}
				>
					<Header>
						{t('currentlyUsing')} <Span>{devices.length}</Span> {t('outOff')} <Span>{device_limit}</Span>
					</Header>
					<AllLogoutButton
						onClick={() => {
							setOpenModel(true)
						}}
						shape="round"
					>
						{t('logoutAllDevices')}
					</AllLogoutButton>
				</FlexLayout>
				<FlexLayout alignItems="stretch" gridGap="2rem" paddingX="2.688rem" flexDirection={{ md: 'row', sm: 'column' }} flexWrap="wrap">
					{devices.map((device, index) => {
						return (
							<DeviceCard
								isCurrentDevice={client.getFingerprint().toString() === device.device_id}
								device={device}
								key={index}
								setDevices={setDevices}
							/>
						)
					})}
				</FlexLayout>
			</FlexLayout>
		</Container>
	)
}

const Container = styled.div`
	border-radius: 15px;
	background: #fff;
	min-height: 90vh;
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	${({ theme }) => `${theme.mediaQueries.large}{}`}
`
const ModelStyle = styled(Modal)`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 32px;
	position: relative;
	overflow-y: auto;
`
const Header = styled.p`
	font-size: 0.875rem;
	font-weight: 600;
	color: #251b33;
	text-align: center;
	line-height: 1.3;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
		padding: 1.938rem 2.688rem;
		line-height: 1;
	}`}
`
const Span = styled.span`
	color: #5ac0fc;
`
const AllLogoutButton = styled(RaisedButton)`
	border-radius: 25px;
	width: 14rem;
	height: 1.5rem;
	margin-inline-end: 0.838rem;
	font-size: 0.8rem;
	font-weight: bold;
	${({ theme }) => `${theme.mediaQueries.large}{
		border-radius: 35px;
		width: 19.013rem;
		height: 3.131rem;
		margin-inline-end: 1.438rem;
		font-size: 1.125rem;
	}`}
`
const CancelButtonConfirm = styled(RaisedButton)`
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	background: #ff8532;
	border-color: #ff8532;
	width: 4.813rem;
	height: 1.438rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 6.813rem;
		height: 2.438rem;
	}`}
`
const ConfirmButton = styled(RaisedButton)`
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	width: 4.813rem;
	height: 1.438rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		width: 6.813rem;
		height: 2.438rem;
	}`}
`
const ContentWrapper = styled(FlexLayout)`
	font-family: Montserrat;
	gap: 1rem;
	${({ theme }) => `${theme.mediaQueries.large}{
		gap: 1.938rem;
	}`}
`
const Text = styled.p`
	font-size: 0.875rem;
	font-style: italic;
	max-width: 33.25rem;
	line-height: 1.22;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
		max-width: 28.25rem;
	}`}
`
const Note = styled.p`
	font-size: 1.125rem;
	font-weight: 600;
	color: #251b33;
	${({ theme }) => `${theme.mediaQueries.large}{
		font-size: 1.125rem;
	}`}
`
export default Devices
